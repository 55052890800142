.header {
    width: 100%;
    height: 90px;
    background-color: rgb(0, 255, 255);
    display: flex;
    
}

.Logo {
    /* width: 900px; */
    display: flex;
    flex-direction: row;
}

.LogoContent {
    width: 420px;
}

.brange {
    padding-left: 2%;
    margin-right: 20px;
}

.HighKingdomLogo {
   width: 250px;
   height: 90px;
}

.NavItems {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}

.NavLink {
    display: flex;
    margin-right: 20px;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    ;
}

.NavTitleLink {
    color: #166e00; 
    text-decoration: none;
    list-style: none;
    font-weight: 600;
}

.NavTitleLink:hover {
    color: #30be0d;
    text-decoration: none;
    list-style: none;
    font-weight: 600;
}

.AuthButton {
    height: 100%;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.colorButtonLogin {
    color: green;
    font-weight: 600;
}

.UserButton {
    display: flex;

}

.HP-Avatar {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 100px;
}

.HP-Username {
    color: #166e00; 
    text-decoration: none;
    list-style: none;
    font-weight: 600;
    margin: 0;
    display: flex;
    text-align: center;
    align-items: center;
    margin-right: 15px;
    transition: 0.4s ease-out;
}

.HP-Username:hover {
    color: #35ff00;
}

.HP-LogoutColor:hover {
    color: #ff0000
}

/* Profile */

@font-face {
    font-family: 'ABC Ginto Normal Trial';
    src: url('../../assets/Fonts/ABC\ Ginto/ABC\ Ginto\ Normal/ABCGintoNormal-Bold-Trial.otf') format('truetype');
}
@font-face {
    font-family: 'ABC Ginto Reular Trial';
    src: url('../../assets/Fonts/ABC\ Ginto/ABC\ Ginto\ Normal/ABCGintoNormal-Medium-Trial.otf') format('truetype');
}

.cardProfile {
    width: 484px;
    height: 610px;
    background: #292B2F;
    border-radius: 20px;
}

.cardBanner {
    width: 484px;
    height: 162px;
    background-color: #212121;
    border-radius: 20px 20px 0px 0px;
}

.cardBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -50px;
    margin-left: 20px;
    border-radius: 100%;
    width: 115px;
    height: 115px;
    background: #292B2F;
}

.container {
    margin-top: 6%;
    width: 460px;
    height: 349px;
    background: #17181A;
    border-radius: 15px;
}

.cardAvatarIcon {
    width: 98px;
    height: 98px;
    border-radius: 100%;
    z-index: 10000;
}

.cardContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
}

.cardUsername {
    font-family: 'ABC Ginto Normal Trial';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
    margin: 0;
    color: #FFFFFF;
}

.cardTabs {
    width: 100;
    height: 13px;
    font-family: 'ABC Ginto Reular Trial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 1px;
    margin-left: 52px;
}

.cardSeparator {
    width: 428px;
    height: 0px;
    border: 1px solid #43414B;
}

.cardAboutMe {
    margin-top: 15px;
    width: 100px;
    height: 13px;
    font-family: 'ABC Ginto Normal Trial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 13px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #B9BBBE;
}

.aboutMe {
    background-color: #17181A;
    text-decoration: none;
    color: floralwhite;
    border: 0;
    margin-left: 10px;
    resize: none;
}

.aboutMe:focus {
    outline: none;
}

.topSep  {
    margin-top: 10px;
}

.Badge {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-top: 5%;
    flex-wrap: wrap;
}

.BadgeWidthController {
    max-width: 320px;
    max-height: 100px;
}

.badgePlace {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 33px;
    align-items: center;

    background: #17181A;
    border-radius: 7px;
}

.BadgeIcon {
	margin-left: 5px;
	width: 27px;
	height: auto; 
	justify-content: space-between;
	display: flex;
	margin-right: 7px;
    background-repeat: no-repeat;
    background-size: contain;
}

.memberBadge {
    width: 37px;
    height: 27px;
}

.highPremiumBadge {
    width: 27px;
    height: 27px;
}

.boosterBadge {
    width: 27px;
    height: 32px;
}

.hamburguer{
    display: none;
}



@media (max-width:783px) {
    .hamburguer{
        display: inline;
        font-size: x-large;
    }
    .brange{
        width: 70px;
    }
    .Logo{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .LogoContent{
        width: 300px;
    }
    .HighKingdomLogo{
        width: 200px;
        height: auto;
    }
    .AuthButton{
        display: inline;
    }
    .NavItems{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 150px;
        gap: 5px;
        margin-right: 0px;
        z-index: 10000;
        background-color: aqua
    }
    .Display{
        display: none !important;
    }
    .header {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:320px) {
    .hamburguer{
        display: inline;
    }
    .hamburguer svg {
        width: 100px !;
    }
    .brange{
        width: 50px;
    }
    .Logo{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .LogoContent{
        width: 300px;
    }
    .HighKingdomLogo{
        width: 100px;
        height: auto;
    }
    .AuthButton{
        display: inline;
    }
    .NavItems{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 150px;
        gap: 5px;
        margin-right: 0px;
        z-index: 10000;
        background-color: aqua
    }
    .Display{
        display: none !important;
    }
    .header {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
    }
}