@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

.HeaderStyle {
    width: 100%;
    height: 90px;
    display: flex;
}

.LandingTitle {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px; 
    margin-bottom: 50px;
    font-family: 'Alfa Slab One', cursive;
    position: relative;
}

.LandingTextDiv {
    display: flex;
    margin-top: 250px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}

.bah {
    width: 100%;
    display: flex;
    justify-content: center;
}

.LandingText {
    width: 550px;
}

.colorTextAqua {
    color: aqua;
    margin: 0;
    margin-left: 4px;
    font-weight: 600;
    text-decoration: none;
}

.strongText {
    margin: 0;
    margin-left: 4px;
    font-weight: 600;
}

/* Memberships Leaderboard */
.memberships {
    margin-top: 25%;
    display: flex;
    justify-content: center;
}

.membershipsBox {
    display: flex;
    width: 950px;
    height: 100%;
    background-color: #383838;
    flex-direction: column;
    border: 2px solid black;
    margin-bottom: 50px;
}


.MF-TitleBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 2px solid black;
}

.MF-Title {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
}

.MF-MemberBox {
    display: flex;
    align-items: center;
    height: 110px;
    background-color: #454545;
}

.MF-Avatar {
    width: 90px;
    height: 90px;
    margin-left: 10px;
    border-radius: 100px;
    background-color: #212121;
}
    
.MF-Username {
    margin: 0;
    margin-left: 20px;
    font-size: 35px;
    font-weight: 600;
}

.MF-usernfo {
    display: flex;
    flex-direction: column;
}

.MF-membersize {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: #a8a8a8;
    width: 150px;
}

.MF-membersizeJoined {
    width: 300px;
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: #a8a8a8;
}

.flex {
    display: flex;
}

.MF-MemberTypeIcon {
    width: 90px;
    height: 90px;
    margin-left: 10px;
    border-radius: 25px;
    background-color: #212121;
}
.MF-MemberType {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
    width: 100%;
}

.hidden {
    overflow: hidden;
}

.scrollCreate {
    overflow: auto;
}

@media (max-width:715px) {
    .membershipsBox {
        display: flex;
        width: auto;
        height: 100%;
        background-color: #383838;
        flex-direction: column;
        border: 2px solid black;
        margin-bottom: 50px;
    }
    .MF-TitleBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 60px;
        border-bottom: 2px solid black;
    }
    .MF-MemberBox {
        display: flex;
        align-items: center;
        height: 110px;
        background-color: #454545;
    }
    .MF-membersize {
        margin: 0;
        margin-left: 20px;
        font-size: 15px;
        color: #a8a8a8;
        width: 150px;
    }
    .MF-MemberTypeIcon {
        width: 50px;
        height: 50px;
        margin-left: 10px;
        border-radius: 25px;
        background-color: #212121;
    }
    .MF-Avatar {
        width: 50px;
        height: 50px;
        margin-left: 10px;
        border-radius: 100px;
        background-color: #212121;
    }
    .MF-Username {
        margin: 0;
        margin-left: 20px;
        font-size: 20px;
        font-weight: 600;
    }
}

@media (max-width:320px) {
    .MF-MemberBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        background-color: #454545;
    }
    .MF-Avatar {
        margin: 0 0 0 20px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: #212121;
    }
    .MF-MemberType {
        display: flex;
        justify-content: center;
        margin-right: 0px;
        width: 100%;
    }
}