.XpColor {
    color: aqua;
}

.LevelColor {
    display: inline-flex;
    height: 5px;
    width: 5px;
    color: rgb(51, 255, 0);
}

.leaderboard {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
}