
.PageWidth {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AuthBox {
    display: flex;
    justify-content: center;
    width: 450px;
    height: 400px;
    background-color: var(--boxColor);
}

.AuthFormDiv {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.authTextField {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 50px;
    background-color: var(--colorTheme);
    border-radius: 25px;
    border: 2px solid #666166;
    margin-bottom: 10px;
    resize: none;
    outline: none;
    color: aliceblue;
    text-align: center;
}

.FormAuthTitle {
    font-size: 55px;
    margin-top: 25px;
}

.center {
    display: flex;
    justify-content: center;
}

.AuthButtonLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-color: var(--secondColor);
    border-radius: 25px;
    border: 2px solid #666166;
    margin-bottom: 10px;
    resize: none;
    outline: none;
    color: aliceblue;
    text-align: center;
    transition: 0.3s;
    animation: ease-out 0.5s normal;
    margin-bottom: 20px;
    cursor: pointer;
}

.AuthButtonLogin:hover {
    background-color: #187202;
    border-color: #35ff00;
    color: aqua;
    width: 250px;
    height: 55px;
}

.separator {
    width: 350px;
    height: 3px;
    background-color: #000000;
    border-radius: 25px;
}

.LoginWithDiscord {
    margin-top: 15px;
    background-color: #5865F2;
    border: 0px;
    border-radius: 25px;
    width: 190px;
    height: 35px;
}

.LoginWithDiscord:hover {
    color: wheat;
}